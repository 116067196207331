import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

const StyledItem = styled.li`
  border: 1px solid black;
  display: block;
  color: #444;
  h2 {
    font-size: 1.2rem;
    text-decoration: none;
    line-height: 1.3;
    /* padding: 0 30px; */
    color: #009bce;
    font-weight: normal;
    margin-top: 15px;
  }
  a {
    text-decoration: none;
  }
  a.imgLink {
    display: block;
    overflow: hidden;
    .gatsby-image-wrapper {
      max-width: 100%;
      height: 200px;
    }
  }
  a:hover h2 {
    text-decoration: underline;
  }
  div.meta {
    position: relative;
    margin: -10px 30px 30px;
    a.authorLink {
      width: 60px;
      height: 67px;
      display: inline-block;
      left: -10px;
      margin-top: -20px;
      position: relative;
    }
  }
  span {
    display: block;
    text-align: right;
    margin-bottom: 1rem;
    font-size: 0.75rem;
    position: absolute;
    top: 15px;
    right: -15px;
  }
`;

const BlogCard = ({ node }) => {
  if (!node || !node.slug) {
    return <></>;
  }
  return (
    <StyledItem key={node.id}>
      {node.featuredImage.node.localFile && node.featuredImage.node.localFile.childImageSharp && (
        <Link to={`/blog/${node.slug}/`} className="imgLink">
          <GatsbyImage
            image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
            alt={node.featuredImage.node.altText}
          />
        </Link>
      )}
      <div className="meta">
        {node.author.node.custom && node.author.node.custom.imageCasual ? (
          <Link to={`/blog/author/${node.author.node.slug}/`} className="authorLink">
            <GatsbyImage
              image={node.author.node.custom.imageCasual.localFile.childImageSharp.gatsbyImageData}
              style={{
                borderRadius: '100%',
                width: '60px',
                height: '60px',
                boxShadow:
                  '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.20)',
              }}
              key={node.id + node.author.node.id}
              alt={node.author.node.name}
            />
          </Link>
        ) : (
          <div style={{ width: '60px', height: '60px', marginBottom: '-20px' }} />
        )}
        <span>{node.date}</span>
        <Link to={`/blog/${node.slug}/`}>
          <h2 dangerouslySetInnerHTML={{ __html: node.title }} />
        </Link>
      </div>
    </StyledItem>
  );
};

export default BlogCard;
