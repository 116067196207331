import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import BlogCard from '../components/BlogCard';

import Layout from '../components/Layout';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Hero from '../components/Hero';

const StyledSection = styled.section`
  background: white;
  /* prevent "featured" top margin from bleeding into the header image */
  &::before {
    content: '';
    display: table;
  }
  .featured {
    margin: 80px auto;
    max-width: 1200px;
    padding: 0 25px;
    box-sizing: border-box;
    color: black;

    .featureImg .gatsby-image-wrapper img {
      height: auto;
    }
    > h2 {
      font-family: 'Oswald';
      font-size: 1.9rem;
      line-height: 1.6;
      margin-bottom: 1.125rem;
      margin-top: 0;
      letter-spacing: 1.4px;
      color: #009bce;
      font-weight: 400;
      transition: all 200ms linear;
      -webkit-font-smoothing: antialiased;
      @media (max-width: 600px) {
        font-size: 1.4rem;
      }
    }
    h3 {
      font-size: 2.2rem;
      text-decoration: none;
      line-height: 1.2;
      color: #009bce;
      font-weight: 400;
      text-transform: uppercase;
      margin-top: 0;
      margin-bottom: 0;
      letter-spacing: 1.5px;
      @media (max-width: 1100px) {
        font-size: 1.5rem;
      }
    }
    a {
      text-decoration: none;
      color: #444;
      display: block;
      overflow: visible;
      &.author {
        color: #009bce;
      }
      &.author-image {
        padding-bottom: 5px;
      }
      .gatsby-image-wrapper {
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12), 0 1px 8px 0 rgba(0, 0, 0, 0.2);
      }
    }
    a:hover h3 {
      text-decoration: underline;
    }

    .flex {
      display: flex;
      justify-content: flex-start;
      & > .featureImg {
        flex: 0 0 50%;
        margin-right: 60px;
        .gatsby-image-wrapper > div {
          /* padding-bottom: calc(43.0945% * 1.4) !important; */
        }
      }
      @media (max-width: 900px) {
        flex-direction: column;
        & > .featureImg {
          margin-right: 0px;
          max-height: 300px;
          overflow: hidden;
        }
      }
      .dateline {
        text-transform: uppercase;
        font-size: 1rem;
        margin-bottom: -3px;
      }
      .cats {
        margin-top: 0.8rem;
        margin-bottom: 1rem;
        h4 {
          display: inline-block;
          margin: 0;
          color: #222;
        }
        .taglist {
          display: inline-block;
          padding-left: 0;
          margin: 0 0 0 0.6rem;
        }
        .taglist li {
          border: 1px solid #555;
          padding: 1px 6px;
          border-radius: 100px;
          display: inline-block;
          text-transform: uppercase;
          font-family: 'Oswald';
          font-size: 12px;
          margin-right: 0.6rem;
          a {
            color: #555;
            text-decoration: none;
          }
          &:hover {
            background-color: #009bce;
            border-color: #006080;
            a {
              color: white;
            }
          }
        }
      }
      .authorBlock {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        text-transform: uppercase;
        font-family: 'Oswald';
        font-size: 18px;
        line-height: 20px;
        color: #444;
        margin-bottom: 1.5rem;
        .gatsby-image-wrapper {
          float: left;
          margin-right: 1rem;
        }
        &:after {
          content: '';
          clear: both;
          display: table;
        }
        p {
          margin-top: 0;
          margin-bottom: 8px;
        }
        p:nth-of-type(1) {
          color: #009bce;
          font-weight: 800;
          letter-spacing: 1.6px;
        }
      }
    }
  }

  .back {
    font-family: 'Oswald';
    padding-left: 25px;
    text-decoration: none;
    display: inline-block;
    color: #009bce;
    font-size: 1.2rem;
    &:before {
      content: '<';
      padding-right: 5px;
    }
  }

  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    > h2 {
      font-family: 'Oswald';
      font-size: 1.9rem;
      line-height: 1.6;
      margin-bottom: 0;
      margin-top: 1.125rem;
      letter-spacing: 1.4px;
      color: #009bce;
      font-weight: 400;
      transition: all 200ms linear;
      -webkit-font-smoothing: antialiased;
      padding-left: 25px;
      @media (max-width: 600px) {
        font-size: 1.4rem;
      }
    }
    .author-bio,
    .category-desc {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px;
      h2 {
        font-family: 'Oswald';
        font-size: 1.9rem;
        line-height: 1.6;
        margin-bottom: 0;
        margin-top: 1.125rem;
        letter-spacing: 1.4px;
        color: #009bce;
        font-weight: 400;
        transition: all 200ms linear;
        -webkit-font-smoothing: antialiased;
        text-align: left;
        @media (max-width: 600px) {
          font-size: 1.4rem;
        }
      }
      p {
        font-family: 'Roboto';
        font-weight: 300;
        letter-spacing: 1.3px;
        font-size: 1rem;
        line-height: 1.3;
        padding: 0 100px;
        color: #000;
        transition: all 200ms linear;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        @media (max-width: 600px) {
          padding: 0;
          text-align: left;
        }
      }
    }

    & > ul.taglist {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      padding: 0 25px;
      box-sizing: border-box;
      margin-left: -25px;
      li {
        flex: 0 0 calc(33% - 25px);
        margin-top: 25px;
        margin-left: 25px;
        @media (max-width: 876px) {
          flex: 0 0 calc(50% - 27px);
        }
        @media (max-width: 600px) {
          flex: 00 100%;
        }
      }
    }
  }

  .pagination {
    margin: 0 auto;
    padding: 2rem 25px 2rem;
    max-width: 800px;
    display: flex;
    justify-content: space-between;
    font-size: 1.5rem;
    background: white;
    font-family: 'Oswald';
    a {
      color: #009bce;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &.next:after {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        background: white;
        border-right: 4px solid #009bce;
        transform: rotate(45deg);
        border-top: 4px solid #009bce;
        margin-left: 10px;
      }
      &.prev:before {
        content: '';
        width: 10px;
        height: 10px;
        display: inline-block;
        background: white;
        border-right: 4px solid #009bce;
        transform: rotate(-135deg);
        border-top: 4px solid #009bce;
        margin-right: 10px;
      }
    }
  }
`;

const FeaturedBlog = ({ node }) => {
  const filteredCategories = node.categories.nodes.filter(
    (val) => val.slug != 'uncategorized' && val.slug != 'remove-from-related-content' && val.slug != 'instant-articles'
  );
  return (
    <div className="featured">
      <h2>Featured Story</h2>
      <div className="flex">
        {node.featuredImage.node.localFile && node.featuredImage.node.localFile.childImageSharp && (
          <Link to={`/blog/${node.slug}/`} className="featureImg">
            <GatsbyImage
              image={node.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
              alt={node.featuredImage.node.altText}
            />
          </Link>
        )}
        <div className="meta">
          <p className="dateline">{node.date}</p>
          <Link to={`/blog/${node.slug}/`}>
            <h3
              className="title is-size-2 has-text-weight-bold is-bold-light"
              dangerouslySetInnerHTML={{ __html: node.title }}
            />
          </Link>
          {filteredCategories && filteredCategories.length ? (
            <div className="cats">
              <h4>Topic: </h4>
              <ul className="taglist">
                {filteredCategories.map((category) => (
                  <li key={`${category.slug}cat`}>
                    <Link to={`/blog/category/${category.slug}/`}>{category.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}

          {/* author.node.custom.imageCasual.localFile.childImageSharp.fixed */}
          {node.author.node.custom && node.author.node.custom.imageCasual && (
            <div className="authorBlock">
              <Link to={`/blog/author/${node.author.node.slug}/`} className="author-image">
                <GatsbyImage
                  image={node.author.node.custom.imageCasual.localFile.childImageSharp.gatsbyImageData}
                  style={{
                    borderRadius: '100%',
                    width: '90px',
                    height: '90px',
                  }}
                  alt={node.author.node.name}
                />
              </Link>
              <div>
                <p>
                  <Link to={`/blog/author/${node.author.node.slug}/`} className="author">
                    {node.author.node.name}
                  </Link>
                </p>
                <p>{node.author.node.custom.role}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

// ($filter: wordpress__POSTFilterInput = { status: { eq: "publish" } })
const BlogIndex = ({ data, pageContext }) => {
  const img = data.img;
  const posts = data.allWpPost.edges;
  const postLinks = posts.map(({ node }, idx) => {
    //format this properly
    if (
      idx === 0 &&
      pageContext.pageNumber === 0 &&
      pageContext.isCategoryPage !== true &&
      pageContext.isAuthorPage !== true
    ) {
      return <div key={node.id}></div>;
    } else {
      return <BlogCard node={node} key={node.id} />;
    }
  });

  const categoryDescriptions = {
    //slug : { desc: "meta desc and page discription"}
    influencers: {
      desc: 'Have you been wondering how to implement an influencer marketing strategy? These posts will help you connect with potential customers on a deeper level.',
    },
    'marketing-automation': {
      desc: 'Are you looking to automate your marketing efforts? These articles will help you reach more prospects and ensure your marketing is working for you 24/7.',
    },
    'marketing-strategy': {
      desc: 'With hundreds of marketing tactics available, how do you choose which ideas to pursue? These posts reveal marketing strategies that benefit your bottom line.',
    },
    'media-relations': {
      desc: 'Pursuing earned media requires strategy and execution. But, when done properly, media relations can lead to significant exposure for your brand.',
    },
    'new-employee': {
      desc: 'Our team of marketing experts bring a wide range of experience. Get to know our newest employees or read on for the scoop on some of our not-so-new Elementals.',
    },
    'pr-brief': {
      desc: 'Looking for marketing tips and advice that are on-point and on-trend. Look no further than our PR briefs. Stay in the know with marketing’s latest and greatest!',
    },
    'public-relations': {
      desc: 'Our PR team has landed some major success for our clients. And, here’s where they share all of their best public relations marketing secrets!',
    },
    recruitment: {
      desc: 'Is recruitment and hiring a challenging task for your business? Let’s advance your recruitment strategy to help you find, qualify, and retain a skilled team!',
    },
    sales: {
      desc: 'We love diving into the hottest new marketing tactics, but ultimately, everything we do is centered around our client’s bottom line. Let’s talk about sales!',
    },
    seo: {
      desc: 'Your business has the product or service solution that customers are searching for! But, can they find you? Here’s what you need to know about SEO. ',
    },
    'social-media': {
      desc: 'Whether you’re looking for extremely targeted ads or the opportunity to go viral, it’s time to start sharing our social media blogs with your marketing team today!',
    },
    'top-lists': {
      desc: 'Want to know the top ways to level up your marketing efforts? These top lists will help you break down which strategies are top-notch ideas for your business.',
    },
    tradeshows: {
      desc: 'Our team has had massive success putting on events (both in person and virtual). These posts will help you get the most out of your next big event.',
    },
    video: {
      desc: 'Video is one of the most engaging ways to connect with your customers. Read these posts for simple tips on improving your video efforts and making a lasting impression.',
    },
    'website-development': {
      desc: 'Your website plays a huge role in your digital marketing efforts. Here’s the latest on web development and what you should look for from your website. ',
    },
  };

  let desc =
    'Stay in the know with the latest integrated marketing news and advice. Our blog keeps things insightful and interesting. Read on to help your business grow.';
  if (pageContext.isAuthorPage) {
    desc = pageContext.description;
  } else if (pageContext.isCategoryPage) {
    if (categoryDescriptions[pageContext.slug]) {
      desc = categoryDescriptions[pageContext.slug].desc;
    }
  }

  let baseURL = data.site.siteMetadata.siteUrl;
  let blogURL = baseURL + '/blog/';

  if (pageContext.isCategoryPage) {
    blogURL = blogURL + 'category/' + pageContext.slug + '/';
  }
  if (pageContext.isAuthorPage) {
    blogURL = blogURL + 'author/' + pageContext.slug + '/';
  }
  if (pageContext.pageNumber > 0) {
    blogURL = blogURL + 'page/' + (pageContext.pageNumber + 1) + '/';
  }

  return (
    <Layout
      title={
        'The Element Water Cooler | Wisconsin Marketing Agency Blog' +
        (pageContext.pageNumber > 0 ? ' - page ' + (pageContext.pageNumber + 1) : '')
      }
      desc={desc}
      ogTitle="The Element Water Cooler | Wisconsin Marketing Agency Blog"
      ogImg={data.site.siteMetadata.siteUrl + data.ogimg.publicURL}
      ogUrl={blogURL}
    >
      <Hero
        heroImg={img.childImageSharp.gatsbyImageData}
        title1="Take a tall drink"
        title2="News That Quenches Your Thirst"
        label="Water Cooler"
      />
      <StyledSection className="section">
        {pageContext.pageNumber === 0 && pageContext.isCategoryPage !== true && pageContext.isAuthorPage !== true && (
          <FeaturedBlog node={posts[0].node} />
        )}
        <div className="container">
          {pageContext.pageNumber === 0 && pageContext.isCategoryPage !== true && pageContext.isAuthorPage !== true && (
            <h2>More Key Insights</h2>
          )}
          {pageContext.isCategoryPage && (
            <>
              <Helmet
                title={
                  `The Element Water Cooler | Posts about ${pageContext.name}` +
                  (pageContext.pageNumber > 0 ? ' - page ' + (pageContext.pageNumber + 1) : '')
                }
              />
              <h2>Category: {pageContext.name}</h2>
              <div className="category-desc">
                <p>{desc}</p>
              </div>
            </>
          )}
          {pageContext.isAuthorPage && (
            <div className="author-bio">
              <Helmet
                title={
                  `The Element Water Cooler | Posts by ${pageContext.name}` +
                  (pageContext.pageNumber > 0 ? ' - page ' + (pageContext.pageNumber + 1) : '')
                }
              />
              <h2>Posts by {pageContext.name}</h2>
              <p>{pageContext.description}</p>
            </div>
          )}
          {pageContext.isCategoryPage && (
            <a href="/blog/" className="back">
              Back to All Articles
            </a>
          )}
          {pageContext.isAuthorPage && (
            <a href="/blog/" className="back">
              Back to All Articles
            </a>
          )}
          <ul className="taglist">{postLinks}</ul>
        </div>
        <div className="pagination">
          {pageContext.previousPagePath ? (
            <Link className="prev" to={pageContext.previousPagePath}>
              Newer Articles
            </Link>
          ) : (
            <div />
          )}
          {pageContext.nextPagePath ? (
            <Link className="next" to={pageContext.nextPagePath}>
              Older Articles
            </Link>
          ) : (
            <div />
          )}
        </div>
      </StyledSection>
    </Layout>
  );
};

export default BlogIndex;
export const pageQuery = graphql`
  query PostsQuery($filter: WpPostFilterInput! = { status: { eq: "publish" } }, $skip: Int = 0, $limit: Int = 10) {
    allWpPost: allWpPost(filter: $filter, skip: $skip, limit: $limit, sort: { order: DESC, fields: [date] }) {
      edges {
        node {
          id
          slug
          title
          excerpt
          date(formatString: "D MMMM, YYYY")
          author {
            node {
              id
              name
              slug
              url
              custom {
                imageCasual {
                  sourceUrl
                  id
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        layout: FIXED
                        width: 160
                        height: 120
                        quality: 90
                        transformOptions: { cropFocus: CENTER }
                      )
                    }
                  }
                }
                role
              }
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 93)
                }
              }
            }
          }
        }
      }
    }
    img: file(relativePath: { eq: "Blog-Hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 1920, quality: 95)
      }
    }
    ogimg: file(relativePath: { eq: "og-img/blog.jpg" }) {
      publicURL
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
